// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
// const firebaseConfig = {
// 	apiKey: "AIzaSyCtxopt-hdw_703BROxdq7YyMzBM9J5r8U",
// 	authDomain: "adagio-eu-dev.firebaseapp.com",
// 	projectId: "adagio-eu-dev",
// 	storageBucket: "adagio-eu-dev.appspot.com",
// 	messagingSenderId: "757099925481",
// 	appId: "1:757099925481:web:947f03d07c1b5af1660578"
// };

const firebaseConfig = {
  apiKey: "AIzaSyBsfWnGodYBCgrKXIONbufRRnzAp7fwhgQ",
  authDomain: "us-server-d7134.firebaseapp.com",
  projectId: "us-server-d7134",
  storageBucket: "us-server-d7134.appspot.com",
  messagingSenderId: "853880757280",
  appId: "1:853880757280:web:8b48db7d4d3dc5563bf045",
  measurementId: "G-YKGH0FLZX0"
};

// const firebaseConfig = {
//   apiKey: "AIzaSyDzzlkJfmtRMgR2ecNE12Ajklc4A_yNspY",
//   authDomain: "eu-server-da743.firebaseapp.com",
//   projectId: "eu-server-da743",
//   storageBucket: "eu-server-da743.appspot.com",
//   messagingSenderId: "151789057621",
//   appId: "1:151789057621:web:511e5d72a53ab3449d86dc",
//   measurementId: "G-CR9PWGCJQ4"
// };

const firebaseConfigBackend = {
	apiKey: "AIzaSyAx3-UMwUpswQhpIsgPRJPpsZCiQwC8QM8",
  authDomain: "cms-server-69a05.firebaseapp.com",
  projectId: "cms-server-69a05",
  storageBucket: "cms-server-69a05.appspot.com",
  messagingSenderId: "828072618447",
  appId: "1:828072618447:web:3177144dab6908a1db8aba"
};

const appBackend = initializeApp(firebaseConfigBackend, "backend")

const authForFirebaseBackend = getAuth(appBackend);

// Initialize Firebase
initializeApp(firebaseConfig);

const auth = getAuth();
const db = getFirestore();
const storage = getStorage();

export { auth, db, storage, authForFirebaseBackend };
